<template>
  <div class="statistics-area">
    <div class="title-line">
      <div class="title-item">A队队员</div>
      <div class="title-item">X坐标</div>
      <div class="title-item">Y坐标</div>
      <div class="title-item">B队队员</div>
      <div class="title-item">X坐标</div>
      <div class="title-item">Y坐标</div>
    </div>
    <div
      class="line"
      v-for="(item, index) in all_x_y_datas.length / 2"
      :key="index"
    >
      <div class="item">
        选手{{ index + 1 }}:<br />{{ all_x_y_datas[index].stu_name }}
      </div>
      <!-- <div class="item">{{  all_x_y_datas[index].x===20 ? -1512 : ((all_x_y_datas[index].x-507.2298717038128)*(1512/560.0)).toFixed(3) }}</div>
      <div class="item">{{  all_x_y_datas[index].y===85 ? 0 : ((31.5-all_x_y_datas[index].y)/0.37058823529411766).toFixed(3) }}</div> -->
      <div class="item">
        {{ all_x_y_datas[index].x.toInchCoordX().toFixed(3) }}
      </div>
      <div class="item">
        {{ all_x_y_datas[index].y.toInchCoordY().toFixed(3) }}
      </div>
      <div class="item">
        选手{{ index + 1 }}:<br />{{ all_x_y_datas[index + 4].stu_name }}
      </div>
      <div class="item">
        {{ all_x_y_datas[index + 4].x.toInchCoordX().toFixed(3) }}
      </div>
      <div class="item">
        {{ all_x_y_datas[index + 4].y.toInchCoordY().toFixed(3) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    all_x_y_datas: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [
        {
          role: "A1",
          x: "00.00",
          y: "00.00",
        },
        {
          role: "A1",
          x: "00.00",
          y: "00.00",
        },
        {
          role: "A1",
          x: "00.00",
          y: "00.00",
        },
        {
          role: "A1",
          x: "00.00",
          y: "00.00",
        },
      ],
      //初始数据
      tableData2: [
        {
          role: "A1",
          x: "00.00",
          y: "00.00",
        },
        {
          role: "A1",
          x: "00.00",
          y: "00.00",
        },
        {
          role: "A1",
          x: "00.00",
          y: "00.00",
        },
        {
          role: "A1",
          x: "00.00",
          y: "00.00",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.statistics-area /deep/ .el-table th {
  color: #ffffff; /* 字体颜色 */
  font-size: 14px;
  background-color: #292a2f;
}

.statistics-area /deep/ .el-table tr,
.el-table td {
  color: #e5dada;
  font-size: 12px;
  background-color: #292a2f;
}
.statistics-area
  /deep/
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: rgba(0, 0, 0, 0) !important;
}
.statistics-area {
  width: 100%;
  height: 100%;
  background-color: #292a2f;
  display: flex;
  flex-direction: column;
  color: #ffffff; /* 字体颜色 */

  .title-line {
    flex: 1;
    display: flex;
    .title-item {
      flex: 1;
      border-right: 1px solid #fff;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }
  .line {
    flex: 1;
    border-top: 1px solid #fff;
    display: flex;
    .item {
      flex: 1;
      border-right: 1px solid #fff;
      font-size: 12px;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }
}
</style>
