<template>
  <div class="game-main-wrap">
    <!-- 重置按钮 -->
    <!-- 全屏提示蒙版---准备比赛场地 -->
    <div class="quanping" v-show="!is_ready">
      <div class="quanping_bg"></div>
      <div class="quanping_font">准备比赛场地中...</div>
    </div>

    <!--  全屏提示蒙版---比赛结束/显示分数-->
    <div class="quanping" v-show="is_over">
      <div class="quanping_bg"></div>
      <div class="quanping_font2">比赛结束（红队:{{score_detail.a_point}} || 黄队:{{score_detail.b_point}}）  <label style="color:red;font-size:20px">{{first_over}}</label></div>
      <div class="point_div">
        比分细则：碰撞扣分（{{score_detail.a_collide_rule}}:{{score_detail.b_collide_rule}}）|| 
                红圈规则（{{score_detail.a_red_rule}}:{{score_detail.b_red_rule}}）||
                最中心规则（{{score_detail.a_center_rule}}:{{score_detail.b_center_rule}}）||
                总分（{{score_detail.a_point}}:{{score_detail.b_point}}）
      </div>

      <div class="point_div2" v-show="match_groud=='下半场'&&totalP!=''">
        <label style="color:red;font-size:20px;font-weight:700">恭喜《{{totalP.sheng}}》进入【{{totalP.order}}】强 请《{{totalP.fu}}》继续加油</label><br><br>
        比分细则（{{totalP.sheng}} vs {{totalP.fu}}）：   总分（{{totalP.sheng_point}}:{{totalP.fu_point}}）|| 红圈（{{totalP.sheng_red}}:{{totalP.fu_red}}）|| 耗时（{{totalP.sheng_time}} : {{totalP.fu_time}}）
      </div>
    </div>

    
    <st-gamemainheader></st-gamemainheader>

    <div class="game-main">
      <!-- <st-gamemainheader></st-gamemainheader> -->

      <p class="last_time_div" v-show="lastThrowTime&&lastThrowTime!=0&&lastThrowTime!='0'">剩余时间：{{lastThrowTime}} 秒</p>
      <p class="last_time_div" v-show="lastThrowTime==''&&lastThrowTime==0&&lastThrowTime=='0'">非击球时间段</p>
      <!-- <p class="last_time_div">剩余时间：{{lastThrowTime}} 秒</p> -->

      <div class="main">
        <CodeArea
          :groupName="res_match_info.group_a_name"
          :isMatcher="isMatcher"
          :isGroup="isAMeGroup"
          @codeChange="codeChange1"
        ></CodeArea>
        <div class="play-area">
          <div class="judger-line">
            <p>{{ judge_txt }}</p>
            <label style="margin-left: 10px; margin-right: 10px">|||</label>
            <p>红队得分：{{ score_a }} 黄队得分：{{ score_b }}</p>
          </div>
          <div class="panoramic-wrap">
            <div ref="detailAAArea" class="play-area-panoramic">
              <PlayArea
                ref="myCanvasPlayA"
                canvasId="myCanvasPlayA"
                :proporting="1"
                :fat_div_width="detailAreaAWidth"
                :fat_div_height="
                  detailAreaAWidth / 8.89 + detailAreaWidth / 8.89
                "
                :allBallsStopCallBack="allBallsStop"
              ></PlayArea>
            </div>
          </div>
          <div class="detail-wrap" ref="detailWrap" style="display: none">
            <div
              ref="detailArea"
              class="play-area-detail"
              :style="{
                width: `${detailAreaWidth}px`,
                transform: `translateX(-${detailtransformParcent}%)`,
              }"
            >
              <PlayArea
                ref="myCanvasPlayB"
                canvasId="myCanvasPlayB"
                :proporting="proporting"
                :fat_div_width="detailAreaWidth"
                :fat_div_height="detailAreaWidth / 8.89"
              ></PlayArea>
            </div>
          </div>
        </div>
        <CodeArea
          :groupName="res_match_info.group_b_name"
          :isMatcher="isMatcher"
          :isGroup="isBMeGroup"
          @codeChange="codeChange2"
        ></CodeArea>
      </div>

      <div class="bottom">
        <ThorwArea
          :isMatcher="isMatcher"
          :isGroup="isAMeGroup"
          img="img/bh_red.png"
          img_dis="img/bh_red_nor.png"
          :playerNameList="res_names_a"
          :active="currue_ball_index_a"
          @onThrowClick="onThrowClick1"
        ></ThorwArea>
        <div class="statistics-area-wrap">
          <StatisticsArea :all_x_y_datas="all_balls_status"></StatisticsArea>
        </div>
        <ThorwArea
          :isMatcher="isMatcher"
          :isGroup="isBMeGroup"
          img="img/bh_yellow.png"
          img_dis="img/bh_yellow_nor.png"
          :playerNameList="res_names_b"
          :active="currue_ball_index_b"
          @onThrowClick="onThrowClick2"
        ></ThorwArea>
      </div>
    </div>

    <!-- 确认击打弹窗 -->
    <el-dialog
      title="击打参数确认"
      :visible.sync="dialog_sure_go"
      width="450px"
      :style="{ 'backgroud-color': '#78aa11' }"
    >
      <span style="font-size: 18px; color: #fff; font-weight: 500"
        >你的编码解析结果为：<br />
        以 y={{ http_return_parmes.y }} || speed={{
          http_return_parmes.speed
        }}
        || angel={{ http_return_parmes.angel }}<br />
        将球打出</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_sure_go = false">取 消</el-button>
        <el-button type="primary" @click="onClickThrowSure">确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import GamemainHeader from "./components/gamemainHeader.vue";
import CodeArea from "./components/codeArea.vue";
import ThorwArea from "./components/throwArea.vue";
import StatisticsArea from "./components/statisticsArea.vue";
import PlayArea from "./components/playarea.vue";
import env from "../../env/env";

import {
  api_race_get_info,
  api_race_code_analysis,
  api_race_data_resercivce,
  api_race_score_statistics,
  api_race_collide_resercivce,
} from "../../util/http/api";

const PIXEL_TO_INCH = 2.7;
const OFFSET_X0 = 506.6666666666666; // 坐标系原点在UI上的像素位置X
const OFFSET_Y0 = 31.48148148148148; // 坐标系原点在UI上的像素位置Y

Number.prototype.toInch = function () {
  return this * PIXEL_TO_INCH;
};
Number.prototype.toInchCoordX = function () {
  return (this - OFFSET_X0).toInch();
};
Number.prototype.toInchCoordY = function () {
  return (OFFSET_Y0 - this).toInch();
};



export default {
  components: {
    "st-gamemainheader": GamemainHeader,
    CodeArea,
    ThorwArea,
    StatisticsArea,
    PlayArea,
  },
  data() {
    return {
      is_ready:false,
      is_over:false,
      dialog_sure_go: false, //确认参数
      http_return_parmes: {}, //http代码解析后返回的值
      http_match_area_data: null,   //通过http实时获取球场上的球的位置
      //计算参数
      match_info_data: {
        group_a_name: "A队",
      },
      detailAreaWidth: 0,
      detailAreaAWidth: 0,
      detailtransformParcent: 0,
      proporting: 0,
      //业务参数
      match_code: "",
      match_groud: "",    //比赛场次
      //是否可以设置界面
      is_can_setting_page: true,
      //权限相关
      isMatcher: false,
      isAMeGroup: false,
      isBMeGroup: false,
      currue_ball_index_a: 0, //当前抛球下标
      currue_ball_index_b: 0, //当前抛球下标
      currue_group: "a", //当前抛球队伍

      codeVal1: "",
      codeVal2: "",
      res_match_info: {}, //赛场基本数据
      res_rel_all_balls: [], //所有球的原始数据
      res_names_a: [],
      res_names_b: [],
      all_balls_status: [], //所有球的实时位置信息
      judge_txt: "公告：比赛正式开始", //公告文字
      score_a: 0,
      score_b: 0,
      last_balls_r: [], //上一局位置情况
      totalP:"",
      first_over:"",
      lastThrowTime:"",
      score_detail:{
        a_center_rule:"0",
        b_center_rule:"0",
        a_red_rule:"0",
        b_red_rule:"0",
        a_collide_rule:"0",
        b_collide_rule:"0",
        a_point:"0",
        b_point:"0",
      }
    };
  },
  methods: {
    /**
     * http请求相关
     */
    //http获取赛场数据
    getMatchInfo() {
      let that = this;
      let params = { match_code: that.match_code };
      this.$http
        .run(api_race_get_info + "?match_code=" + that.match_code+"&match_groud="+that.match_groud, params, {
          type: "get",
          loginApi: true,
        })
        .then((res) => {
          that.res_match_info = res.data;
          that.setMarchGraghy(); //得到数据后设置界面
        });
    },
    //http获取代码解析结果
    getCodeRelx(py_code_str) {
      let that = this;
      //console.log(that.res_match_info.group_small_name)
      //获取组别
      let group_type =
        that.res_match_info.group_small_name.indexOf("初中") > -1
          ? "初中组"
          : that.res_match_info.group_small_name.indexOf("高中") > -1
          ? "高中组"
          : "未识别组别";
      //获取所有球的位置
      let all_balls_arr_http_query = [];
      for (let index = 0; index < that.all_balls_status.length; index++) {
        const element = that.all_balls_status[index];
        let item_arr = [
          element.x.toInchCoordX().toFixed(3),
          element.y.toInchCoordY().toFixed(3),
        ];
        all_balls_arr_http_query.push(item_arr);
      }
      //console.log(JSON.stringify(all_balls_arr_http_query))
      let params = {
        race_type: group_type,
        balls_arr: JSON.stringify(all_balls_arr_http_query),
        py_code: py_code_str,
      };
      this.$http
        .run(api_race_code_analysis, params, { type: "post", loginApi: true })
        .then((res) => {
          that.http_return_parmes = res.data;
          //console.log(res)
          //console.log(that.http_return_parmes.angel)
          if (that.http_return_parmes.y) {
            that.dialog_sure_go = true;
          } else {
            that.$message({
              message: res.data.err_msg,
              type: "error",
            });
          }
        });
    },
    //http击打结果回传
    setHttpThrowBall(balls_name_,balls_data_) {
      let that = this;
      let params = {
        groud_order: that.match_groud,
        match_code: that.match_code,
        balls_name: balls_name_,
        balls_data:balls_data_
      };
      this.$http
        .run(api_race_data_resercivce , params, {
          type: "post",
          loginApi: true,
        })
        .then((res) => {
          // that.res_match_info = res.data;
          that.getMatchBigData(); //得到数据后设置界面
          //console.dir(res.data);    //成功击打，数据刷入成功
        });
    },
    //http获取赛场实时数据  (3s刷新一次)
    getMatchBigData() {
      let that = this;
      let params = { };
      this.$http
        .run(api_race_score_statistics + "?match_code=" + that.match_code+"&match_groud="+that.match_groud, params, {
          type: "get",
          loginApi: true,
        })
        .then((res) => {
          that.http_match_area_data = res.data;
          //如果球没有行动时刷新数据
          if(that.is_can_setting_page){
              //设置可编辑权限
              that.isMatcher = res.data.judge_detail.isMatcher;
              that.isAMeGroup = res.data.judge_detail.isAMeGroup;
              that.isBMeGroup = res.data.judge_detail.isBMeGroup;
              that.currue_ball_index_a = res.data.judge_detail.currue_ball_index_a;
              that.currue_ball_index_b = res.data.judge_detail.currue_ball_index_b;
              that.currue_group = res.data.judge_detail.currue_group;
              //设置球场数据
              that.setAllBallsFromHttp();
              //设置分数
              that.score_a = res.data.score_info.group_a_score;
              that.score_b = res.data.score_info.group_b_score;
              that.score_detail = res.data.score_detail;
              //标记结束
              if(res.data.match_info.match_status=="2"){
                  that.is_over = true;
              }

              //标记剩余时间
              that.lastThrowTime = res.data.lastThrowTime;
              
              //总结局分
              if(res.data.totalP){
                that.totalP = res.data.totalP
              }else{
                that.totalP = ""
              }
          }
        });
    },

    //http击打结果回传
    setHttpCollideBall(ball_data_) {
      let that = this;
      let params = {
        groud_order: that.match_groud,
        match_code: that.match_code,
        ball_data: ball_data_
      };
      this.$http
        .run(api_race_collide_resercivce , params, {
          type: "post",
          loginApi: true,
        })
        .then((res) => {
          if(res.code == 'msg_100'){
            that.$message({
                message: "全场前三球发生碰撞，团队分数扣一分",
                type: "warning",
              });
          }
        });
    },


    //所有球停止碰撞 //设置数据  所有球的数据  throwingBall当前击打的球 hasImpact是否发生击打
    allBallsStop(balls, throwingBall, hasImpact) {
      console.log("碰撞是否发生："+hasImpact)
      this.is_can_setting_page = true;
      this.setHttpThrowBall(throwingBall.ball_name,JSON.stringify(balls));
      let that = this;
      //判断是否为前三球
      for (let index = 0; index < balls.length; index++) {
        const item = balls[index];
        //找到当前抛出球的位置
        console.log("碰撞是否发生11："+item.ball_name+"=="+throwingBall.ball_name)
        if (item.ball_name === throwingBall.ball_name) {
          let idx = index;
          console.log("碰撞是否发生22："+idx);
          //判断前三球是否发生碰撞
          if ([0, 4, 1].indexOf(idx) > -1 && hasImpact == true) {
            that.setHttpCollideBall(throwingBall);
          }
        }
      }
      //统计得分
      if (this.currue_ball_index_b === 4) {
        this.judge_txt = "公告：" + "比赛结束！";
      }
    },
    
    ///////////////////////////////////尺寸计算 完结/////////////////////////////////
    setDetailAreaWidth() {
      let height = this.$refs.detailWrap.offsetHeight;
      this.detailAreaWidth = height * 8.89;
      setTimeout(() => {
        this.detailAreaAWidth = this.$refs.detailAAArea.offsetWidth;
      }, 500);
    },
    setDetailAreaParcent() {
      let width = this.$refs.detailWrap.offsetWidth;
      let limit = 100 - (width / this.detailAreaWidth) * 100;
      this.detailtransformParcent = limit - 3;
    },
    getElementProporting() {
      let width = this.$refs.detailWrap.offsetWidth;
      let proporting = this.detailAreaWidth / width;
      this.proporting = proporting;
    },
    //////////////////////////////////业务逻辑///////////////////////////////////
    //设置赛场场数据 完结
    setMarchGraghy() {
      let meId = parseInt(this.res_match_info.me_id);
      let userAids = [];
      let userBids = [];
      for (
        let index = 0;
        index < this.res_match_info.a_personals.length;
        index++
      ) {
        let item = this.res_match_info.a_personals[index];
        userAids.push(item.id);
      }
      for (
        let index = 0;
        index < this.res_match_info.b_personals.length;
        index++
      ) {
        let item = this.res_match_info.b_personals[index];
        userBids.push(item.id);
      }
      //设置所有 的球
      this.setAllBalls();
    },

    //初始化所有的球  完结
    setAllBalls() {
      let areaA = this.$refs.myCanvasPlayA;
      let ballsData = [];
      this.res_names_a = [];
      for (
        let index = 0;
        index < this.res_match_info.a_personals.length;
        index++
      ) {
        let aItem = this.res_match_info.a_personals[index];
        let item = {};
        item.ball_x = 20;
        item.ball_y = 85;
        item.ball_type = "red";
        item.ball_name = "ball_" + this.match_code + "_" + aItem.id;
        item.stu_name = aItem.ky_user_info.real_name;
        ballsData.push(item);
        this.res_names_a.push(aItem.ky_user_info.real_name);
      }
      for (
        let index = 0;
        index < this.res_match_info.b_personals.length;
        index++
      ) {
        let aItem = this.res_match_info.b_personals[index];
        let item = {};
        item.ball_x = 20;
        item.ball_y = 85;
        item.ball_type = "yellow";
        item.ball_name = "ball_" + this.match_code + "_" + aItem.id;
        item.stu_name = aItem.ky_user_info.real_name;
        ballsData.push(item);
        this.res_names_b.push(aItem.ky_user_info.real_name);
      }
      this.res_rel_all_balls = ballsData;
      areaA.initAllBall(ballsData);
    },

    //复原赛场所有球的位置
    setAllBallsFromHttp() {
      if(this.http_match_area_data==null || this.http_match_area_data.all_balls_data=={}){
        return
      }
      let balls_http = eval(this.http_match_area_data.all_balls_data.balls_data);
      let areaA = this.$refs.myCanvasPlayA;
      areaA.setBallsPos(balls_http);   //还原赛场
    },

    //TODO 刷新场上所有球的位置并显示 完结
    refresh_all_balls_point() {
      //实时刷新场上所有球的位置
      let that = this;
      let timeTools = setInterval(() => {
        let areaA = that.$refs.myCanvasPlayA;
        that.all_balls_status = areaA.balls;
        // console.log("============================")
        // console.dir(that.res_match_info)
        // console.dir(that.currue_ball_index_a)
        if (that.currue_group === "a" && that.currue_ball_index_a <= 3) {
          that.judge_txt =
            "公告：" +
            "请“" +
            that.res_match_info.group_a_name +
            "”队伍“" +
            that.res_match_info.groupA[that.currue_ball_index_a].ky_user_info
              .real_name +
            "”队员编码投掷冰壶";
        } else if (that.currue_group === "b" && that.currue_ball_index_b <= 3) {
          that.judge_txt =
            "公告：" +
            "请“" +
            that.res_match_info.group_b_name +
            "”队伍“" +
            that.res_match_info.groupB[that.currue_ball_index_b].ky_user_info
              .real_name +
            "”队员编码投掷冰壶";
        }
        if (that.currue_ball_index_b === 4) {
          window.clearInterval(timeTools);
        }
      }, 1000);
    },

    //点击弹窗确定
    onClickThrowSure(){
      this.dialog_sure_go = false;
      ////将球抛出
      this.throw_balls_to_area(this.http_return_parmes.y,this.http_return_parmes.speed,this.http_return_parmes.angel);
    },
    //实时获取左边编码区域代码
    codeChange1(data) {
      this.codeVal1 = data;
    },
    //实时获取右边编码区域代码
    codeChange2(data) {
      this.codeVal2 = data;
    },
    //点击左侧按钮击出
    onThrowClick1() {
      this.getCodeRelx(this.codeVal1);
      return;
    },
    //点击右侧按钮击出
    onThrowClick2() {
      this.getCodeRelx(this.codeVal2);
      return;
    },
    //将球抛出去
    throw_balls_to_area(y,speed,angel) {
      this.is_can_setting_page = false;
      let areaA = this.$refs.myCanvasPlayA;
      this.last_balls_r = areaA.balls;
      if (this.currue_group === "a") {
        let ballName = this.res_rel_all_balls[this.currue_ball_index_a].ball_name;
        areaA.throwBallItem(
          ballName,
          0,
          y,
          speed,
          angel
        );
      }else if (this.currue_group === "b") {
        let ballName = this.res_rel_all_balls[this.currue_ball_index_b + 4]
          .ball_name;
        areaA.throwBallItem(
          ballName,
          0,
          y,
          speed,
          angel
        );
        if (this.currue_ball_index_b === 4) {
          this.isAMeGroup = false;
          this.isBMeGroup = false;
        }
      }
    },
  },
  mounted() {
    let that =  this;
    that.first_over = "";
    setTimeout(()=>{
      that.is_ready = true
    },5000);
    //计算相关
    this.setDetailAreaWidth();
    this.setDetailAreaParcent();
    this.getElementProporting();
    //获取场次信息
    this.match_code = this.$route.query.code;
    this.match_groud = this.$route.query.groud;
    this.getMatchInfo(); //获取赛场数据
  
    //比赛时间是否到了
    let matchTime = new Date("2021/9/25 9:00:00").getTime();
    let nowTime = new Date().getTime();
    if(env.server_env_name=='prod'&&nowTime<matchTime){
      this.judge_txt = "比赛未开始，请关注自己的比赛时间";
      that.$message({
                message: "比赛未开始，请关注自己的比赛时间",
                type: "warning",
              });
    }
    //刷新场上数据  3s一次
    setInterval(()=>{
      if(!that.is_over){
        that.getMatchBigData();
      }else{    //结束了之后跳转到第二场
        if(that.match_groud=="上半场"){
          that.first_over = "（请刷新浏览器交换先后手，2分钟内开始下半场）"
          this.$router.push({
            path: "/gamemainrace",

            query: {
              code: that.match_code,
              groud: "下半场"
            },
          });
          this.routerRefresh();
          //this.$router.go(0);
        }
      }
    },5000);
    //实时获取场上所有球的状态
    this.refresh_all_balls_point();
  },
};
</script>

<style lang="scss" scoped>
// 重写dialog
/deep/ .el-dialog {
  text-align: left;
  font-weight: 700;
  position: relative;
  color: #fff;
  margin: 0 auto 50px;
  border-radius: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  background-color: #111;
  // background-image: url("/img/person_back.png");
}

/deep/ .el-dialog__title{
  line-height: 24px;
  font-size: 18px;
  color: #fff;
}

.chongzhi_btn {
  position: absolute;
  z-index: 2000;
  right: 100px;
  top: 200px;
}

.last_time_div{
  color: #fff;
  font-size: 18px;
  position: absolute;
  z-index: 800;
  top: 100px;
  left: 10px;
}

.quanping{
  width: 100%;
  height: 135vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.quanping_bg{
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(2px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
}

.quanping_font{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  background-color: #333;
  width: 100%;
  height: 27vh;
  margin-top: 30vh;
  text-align: center;
  vertical-align: middle;
  padding-top: 12vh;
}

.quanping_font2{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  background-color: #333;
  width: 100%;
  height: 7vh;
  //margin-top: 70vh;
  text-align: center;
  vertical-align: middle;
  padding-top: 2vh;
}
.point_div{
  position: absolute;
  top: 7vh;
  padding-bottom: 7vh;
  left: 0;
  z-index: 200;
  color: #fff;
  font-weight: 500;
  background-color: #333;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
.point_div2{
  position: absolute;
  top: 17vh;
  padding-bottom: 7vh;
  left: 0;
  z-index: 200;
  color: #fff;
  font-weight: 500;
  background-color: #333;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}

.game-main-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 1200px;
  background-image: url("/img/login_back_ico.png");
  background-size: cover;
  background-attachment: fixed;
  background-color: #cccccc;
  height: 135vh;
  padding-bottom: 10px;
  box-sizing: border-box;
  .game-main {
    display: flex;
    flex-direction: column;
    width: 1200px;
    min-width: 1200px;
    //width: 1300px;
    //height: 90vh;
    height: 800px;
    padding-bottom: 10px;
    box-sizing: border-box;

    .main {
      min-height: 400px;
      flex: 3;
      display: flex;
      margin: 20px 0;
      overflow: hidden;
      padding: 0 20px;
      .play-area {
        margin: 0 20px;
        flex: 1;
        width: 800px;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        .judger-line {
          padding-left: 10px;
          color: darkblue;
          font-weight: 700;
          font-size: 12px;
          height: 60px;
          background: #ddd;
          display: flex;
          align-items: center;
        }
        .panoramic-wrap {
          height: 0;
          // padding-top: 11.243386%;
          padding-top: 11.24%;
          margin: 10px 0;
          position: relative;
          left: 0;
          top: 0;
          .play-area-panoramic {
            width: 100%;
            height: 100%;
            background: green;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .detail-wrap {
          flex: 1;
          overflow: hidden;
          // overflow: auto;
          .play-area-detail {
            // width: 100%;
            height: 100%;
            min-height: 370px;
            background: pink;
          }
        }
      }
    }
    .bottom {
      display: flex;
      min-height: 240px;
      flex: 1;
      padding: 0 20px;

      .statistics-area-wrap {
        margin: 0 20px;
        flex: 1;
        border-radius: 16px;
        overflow: hidden;
      }
    }
  }
}
</style>
