<template>
  <div class="person">
    <!-- <div class="person_header"></div> -->
    <!-- 头部 -->
    <s-personheader class="personheade"></s-personheader>
    <!-- 内容 -->
    <div class="person_body">
      <div class="person_body_box">
        <!-- 有参赛信息 -->
        <div class="person_body_has_math">
          <el-tabs
            v-model="configTab"
            :tab-position="'left'"
            style="height: 100%"
            @tab-click="change"
          >
            <el-tab-pane label="创建队伍" name="entry01">
              <s-personEntry-a></s-personEntry-a>
            </el-tab-pane>

            <el-tab-pane label="报名信息" name="entry02">
              <s-personEntry-b></s-personEntry-b>
            </el-tab-pane>

            <el-tab-pane label="我的练习" name="practice">
              <s-practiceAndMatch
                :practiceAndmatchMsg="practiceDetail"
              ></s-practiceAndMatch>
            </el-tab-pane>
            <el-tab-pane label="我的比赛" name="match">
              <s-practiceAndMatch
                :practiceAndmatchMsg="matchDetail"
              ></s-practiceAndMatch>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PersonHeader from "./components/personHeader.vue";
//import PersonEntry from "./components/personEntry";
import PersonEntry01 from "./components/personEntry_01";
import PersonEntry02 from "./components/personEntry_02";
import practiceAndMatch from "./components/practiceAndMatch.vue";
export default {
  components: {
    "s-personheader": PersonHeader,
    //"s-personEntry": PersonEntry,
    "s-personEntry-a": PersonEntry01,
    "s-personEntry-b": PersonEntry02,
    "s-practiceAndMatch": practiceAndMatch,
  },
  data() {
    return {
      entryMsg: {
        name: "",
      },
      configTab: "entry01",
      practiceAndmatchMsg: {
        type: "practice",
      },
      practiceDetail: {
        type: "practice",
      },
      matchDetail: {
        type: "match",
      },
    };
  },
  created() {},
  methods: {
    change(e) {
      console.log(e.name);
      // if (e.name === "entry") {
      //   // this.$refs.personEntry.queryEntryMsg();
      // }
    },
  },
};
</script>

<style scoped>
.person {
  min-width: 1024px;
}
.person_body {
  background-image: url("/img/person_back.png");
  background-repeat: no-repeat;
  background-size: 100% 50%;
  padding-top: 24px;
}
.person_body_box {
  width: 900px;
  min-height: 776px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 16px 30px 0px rgba(14, 93, 197, 0.1);
  border-radius: 8px;
  margin: 0 auto;
}
.person_body_has_math {
  padding-top: 24px;
  min-height: 752px;
  height: auto;
}
</style>
<style>
.person_body .person_body_box .el-tabs__item {
  width: 228px;
  text-align: left;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 500;
}
.person_body .person_body_box .is-active {
  background: rgba(7, 85, 167, 0.1);
  color: #0755a7;
}
</style>
