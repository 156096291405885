<template>
  <div class="person">
    <div class="person_header"></div>
    <!-- 头部 -->
    <s-personheader class="personheade"></s-personheader>
    <!-- 内容 -->
    <div class="person_body">
      <div class="person_body_box">
        <div clsss="suses">
          <!--列出我的所有比赛-->
          <div class="circuit_box_title">我的比赛</div>

          <div
            v-if="matchList.length === 0"
            style="color: gray; font-size: 20px"
          >
            抱歉！查询不到您的比赛信息
          </div>

          <div v-else>
            <el-card
              className="box-card text item "
              class="point_div bg_card"
              v-for="(item, index) in matchList"
              :key="index"
              @click.native="to_match_area(index)"
            >
              <el-row :gutter="20">
                <el-col :span="6">
                  <div class="grid-content bg-purplev padding_tim">
                    比赛编号：{{ item.code }}
                  </div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple padding_tim">
                    比赛阶段：{{ item.match_type }} -
                    {{ item.group_small_name }}
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple padding_tim">
                    比赛时间：{{ item.match_time }}
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="grid-content bg-purple padding_tim">
                    比赛状态：{{
                      item.match_status === "0"
                        ? "未开始"
                        : item.match_status === "1"
                        ? "正在比赛"
                        : "比赛结束"
                    }}
                  </div>
                </el-col>

                <el-col :span="18">
                  <div class="grid-content bg-purple padding_tim">
                    比赛队伍：{{ item.group_a_name }} VS {{ item.group_b_name }}
                  </div>
                </el-col>

                <el-col :span="6">
                  <div class="grid-content bg-purple padding_tim">
                    <div class="blue-time">进入比赛</div>
                  </div>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { matchListGet } from "../../util/http/api";
import PersonHeader from "./components/personHeader.vue";

export default {
  components: {
    "s-personheader": PersonHeader,
  },
  data() {
    return {
      matchList: [],
    };
  },
  created() {
    this.getHttpMatchList();
  },
  mounted() {},
  methods: {
    //获取比赛记录
    getHttpMatchList() {
      let that = this;
      this.$http
        .run(matchListGet, "", { type: "get", loginApi: true })
        .then((res) => {
          console.log(res);
          that.matchList = res.data;
        });
    },
    to_match_area(index) {
      let code_m = this.matchList[index].code;
      let groud_m = this.matchList[index].match_groud;
      console.log("点击了+" + index);

      let match_group_key = this.matchList[index].group_small_name;

      // if(match_group_key.indexOf("高中")!=-1){
      //   this.$router.push({
      //   path: "/gamemainraceheight",
      //   query: {
      //     code: code_m,
      //     groud: groud_m
      //   },});
      // }else{
        this.$router.push({
          path: "/gamemainrace",
          query: {
            code: code_m,
            groud: groud_m
          },
        });
        //window.open(routeUrl.href, '_blank');
      // }
      
      this.routerRefresh();
    },
  },
};
</script>

<style scoped>
.person {
  min-width: 1024px;
}

.bg_card {
  text-align: left;
  margin-top: 10px;
}

.person_body {
  background-image: url("/img/person_back.png");
  background-repeat: no-repeat;
  background-size: 100% 50%;
  padding-top: 24px;
}

.person_body_box {
  width: 900px;
  height: 776px;
  margin: 0 auto;
}

.suses {
  /*top: -200px;*/
  /*position: absolute;*/
  /*color: aliceblue;*/
}

.circuit_box_title {
  font-size: 32px;
  /*font-family: PingFangSC-Semibold, PingFang SC;*/
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 600;
  color: #ffffff;
  line-height: 45px;
  margin: 0 auto 84px;
}

.padding_tim {
  padding-top: 10px;
  padding-bottom: 10px;
}

.point_div {
  cursor: pointer;
}
.point_div:hover {
  transform: scale(1.05);
}
.blue-time {
  color: #20a0ff;
}
</style>
