<template>
  <div class="person">
    <div class="person_header"></div>
    <!-- 头部 -->
    <s-personheader class="personheade"></s-personheader>
    <!-- 内容 -->
    <div class="person_body">
      <div class="person_body_box">
        <div
          clsss="suses"
          style="font-weight: 500; font-size: 26px; color: #ffffff"
        >
          <br /><br />登录成功后
          <!-- <label ref="time_div"></label> 自动返回首页 -->
          <label ref="time_div">{{ count + 1 }}s</label> 自动返回首页
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Oidc from "oidc-client";
import PersonHeader from "./components/personHeader.vue";
export default {
  components: {
    "s-personheader": PersonHeader,
  },
  data() {
    return {
      count: "",
    };
  },
  created() {
    this.register_call();
  },
  mounted() {},
  methods: {
    register_call() {
      var config = {
        loadUserInfo: true,
        filterProtocolClaims: true,
        response_mode: "query",
      };
      config.scope = "openid profile roles extoken_role";
      var mgr = new Oidc.UserManager(config);
      mgr.signinRedirectCallback().then(function (user) {
        //console.log(user);
        if (user.profile.real_name) {
          //有完整信息则写缓存
          localStorage.setItem("ky_login_info", JSON.stringify(user));
        }
      });
      this.goIndexPage();
    },
    goIndexPage() {
      const TIME_COUNT = 5;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
            //跳转的页面写在此处
            this.$router.push({ path: "/" });
            //this.routerRefresh();
          }
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.person {
  min-width: 1024px;
}

.person_body {
  background-image: url("/img/person_back.png");
  background-repeat: no-repeat;
  background-size: 100% 50%;
  padding-top: 24px;
}

.person_body_box {
  width: 900px;
  height: 776px;
  margin: 0 auto;
}

.suses {
  top: -200px;
  position: absolute;
  color: aliceblue;
}
</style>
