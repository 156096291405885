<template>
  <div class="person_entry">
    <!-- 创建队伍 -->

    <!-- 老师身份 -->
    <div class="entry_box">
      <div class="title">队伍信息</div>

      <div
        class="person_entry_rach"
        v-for="item in me_group_list"
        :key="item.id"
      >
        <div class="person_entry_table_title">
          <b
            >队伍报名号：<label style="color: green; font-size: 20px">{{
              item.group_pwd
            }}</label>
          </b>
        </div>
        <div class="person_entry_table_title">
          <label style="color: #000; font-size: 12px">
            （“队伍报名号”为组队唯一凭证，请创建队伍者尽快将该号码发送给队友及指导老师，并通知他们尽快完成组队）
          </label>
        </div>
        <div class="person_entry_table_title">
          <b>队伍名称：{{ item.group_name }} </b>
        </div>
        <!-- 队员报名人数 -->
        <div class="person_entry_table_title">
          <b>当前队员报名人数（{{ item.userInfo.length }}/4 ）</b>
        </div>
        <div
          class="person_entry_table_title"
          style="font-size: 18px; line-height: 32px"
        >
          队员1.{{
            item.userInfo[0] ? item.userInfo[0].ky_user_info.real_name : "空缺"
          }}
          <label
            style="color: blue"
            @click="setTop('1', item.userInfo[0].id, '4', '4')"
            v-show="item.userInfo[0] && me_group_list[0].group_sure == '0'"
          >
            ⬆
          </label>
          <label
            style="color: blue"
            @click="setTop('1', item.userInfo[0].id, '3', '4')"
            v-show="item.userInfo[0] && me_group_list[0].group_sure == '0'"
          >
            ⬇
          </label>
          <br />
          队员2.{{
            item.userInfo[1] ? item.userInfo[1].ky_user_info.real_name : "空缺"
          }}
          <label
            style="color: blue"
            @click="setTop('1', item.userInfo[1].id, '4', '3')"
            v-show="item.userInfo[1] && me_group_list[0].group_sure == '0'"
          >
            ⬆
          </label>
          <label
            style="color: blue"
            @click="setTop('1', item.userInfo[1].id, '2', '3')"
            v-show="item.userInfo[1] && me_group_list[0].group_sure == '0'"
          >
            ⬇
          </label>
          <br />
          队员3.{{
            item.userInfo[2] ? item.userInfo[2].ky_user_info.real_name : "空缺"
          }}
          <label
            style="color: blue"
            @click="setTop('1', item.userInfo[2].id, '3', '2')"
            v-show="item.userInfo[2] && me_group_list[0].group_sure == '0'"
          >
            ⬆
          </label>
          <label
            style="color: blue"
            @click="setTop('1', item.userInfo[2].id, '1', '2')"
            v-show="item.userInfo[2] && me_group_list[0].group_sure == '0'"
          >
            ⬇
          </label>
          <br />
          队员4.{{
            item.userInfo[3] ? item.userInfo[3].ky_user_info.real_name : "空缺"
          }}
          <label
            style="color: blue"
            @click="setTop('1', item.userInfo[3].id, '2', '1')"
            v-show="item.userInfo[3] && me_group_list[0].group_sure == '0'"
          >
            ⬆
          </label>
          <label
            style="color: blue"
            @click="setTop('1', item.userInfo[3].id, '1', '1')"
            v-show="item.userInfo[3] && me_group_list[0].group_sure == '0'"
          >
            ⬇
          </label>
        </div>

        <!-- 指导老师报名人数 -->
        <div class="person_entry_table_title">
          <b>当前指导老师报名人数（{{ item.teaInfo.length }}/3 ）</b>
        </div>
        <div
          class="person_entry_table_title"
          style="font-size: 18px; line-height: 32px"
        >
          <!-- identify_code,index,newOrder,oldOrder -->
          指导老师1.{{
            item.teaInfo[0] ? item.teaInfo[0].ky_user_info.real_name : "空缺"
          }}
          <label
            style="color: blue"
            @click="setTop('2', item.teaInfo[0].id, '3', '3')"
            v-show="item.teaInfo[0] && me_group_list[0].group_sure == '0'"
          >
            ⬆
          </label>
          <label
            style="color: blue"
            @click="setTop('2', item.teaInfo[0].id, '2', '3')"
            v-show="item.teaInfo[0] && me_group_list[0].group_sure == '0'"
          >
            ⬇
          </label>
          <br />
          指导老师2.{{
            item.teaInfo[1] ? item.teaInfo[1].ky_user_info.real_name : "空缺"
          }}
          <label
            style="color: blue"
            @click="setTop('2', item.teaInfo[1].id, '3', '2')"
            v-show="item.teaInfo[1] && me_group_list[0].group_sure == '0'"
          >
            ⬆
          </label>
          <label
            style="color: blue"
            @click="setTop('2', item.teaInfo[1].id, '1', '2')"
            v-show="item.teaInfo[1] && me_group_list[0].group_sure == '0'"
          >
            ⬇
          </label>
          <br />
          指导老师3.{{
            item.teaInfo[2] ? item.teaInfo[2].ky_user_info.real_name : "空缺"
          }}
          <label
            style="color: blue"
            @click="setTop('2', item.teaInfo[2].id, '2', '1')"
            v-show="item.teaInfo[2] && me_group_list[0].group_sure == '0'"
          >
            ⬆
          </label>
          <label
            style="color: blue"
            @click="setTop('2', item.teaInfo[2].id, '1', '1')"
            v-show="item.teaInfo[2] && me_group_list[0].group_sure == '0'"
          >
            ⬇
          </label>
        </div>

        <!-- 备注 -->
        <div class="person_entry_table_title">
          <label style="color: red">
            重要说明
            <br />1.一人仅有权限组队一次，请谨慎核实所填报“组别”。
            <br />2.组队时，请确保“队伍报名号”填写正确，若因该号码输入错误造成的组队错误或组队失败，从而影响正常参赛，责任自负！
            <br />3.仅创建队伍者在报名截止前有权限调整4位选手及指导老师的排名。竞赛时，平台将根据排名依次给予每位选手编程投掷一个冰壶的权限，请创建队伍者在选手全部进队后确认最终竞赛的投掷顺序（提醒：根据规则，第四位队员为队长），指导老师排序也请一并确认。
            <br />4.一队指导老师至多三人。
          </label>
        </div>

        <!-- 组队信息确认按钮 -->
        <div
          class="sign_up_btn"
          @click="dialogVisible1 = true"
          v-show="me_group_list[0].group_sure == '0'"
        >
          确认队伍信息
        </div>

        <el-divider></el-divider>
      </div>

      <div class="build_team" @click="buildNewTeam" v-show="isCanCreate">
        新建队伍
      </div>
    </div>
    <!-- 创建队伍的弹窗 -->
    <el-dialog
      title="创建队伍"
      :visible.sync="dialogFormVisible"
      style="width: 50%; margin-left: 25%"
    >
      <el-form :model="formCreateGroup">
        <el-form-item label="队伍名称" :label-width="formLabelWidth">
          <el-input
            v-model="formCreateGroup.group_name"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="组别" :label-width="formLabelWidth">
          <el-select
            v-model="formCreateGroup.group_type"
            placeholder="请选择组别"
          >
            <el-option label="初中组" value="初中组"></el-option>
            <el-option label="高中组" value="高中组"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitCreateGroup">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 确认报名信息 -->
    <el-dialog
      title="确认队伍信息、顺序"
      :visible.sync="dialogVisible1"
      width="30%"
      :before-close="handleClose"
    >
      <span>请仔细核对您的队伍信息，点击确认后，不可修改</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sure_info">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  allGroupList,
  identify,
  reportReq,
  reportInfoReq,
  reportCreateGroup,
  getMyGroupList,
  topGroup,
  sureGroup,
} from "../../../util/http/api";

export default {
  data() {
    return {
      dialogVisible1: false,
      identify_role: "0", // 0游客  1老师  2学生  身份
      is_report_success: "0", // 1是 0否 报名成功
      formLabelAlign: {},
      dialogFormVisible: false, //创建队伍弹窗
      formLabelWidth: "80px",
      isCanCreate: false,
      formCreateGroup: {
        group_name: "",
        group_type: "初中组",
      },
      //报名选队伍
      groupList: [],
      sel_group_name: "",
      groupFormReport: {
        group_name: "",
        group_type: "",
        id: 0,
        teacher_id: 0,
        teacher_name: "",
        school_name: "",
      },
      //报名信息
      report_info: {},
      me_group_list: [],
    };
  },
  created() {
    this.getIdentifyInfo(); //获取身份信息
    this.queryEntryMsg(); //获取报名信息
    this.getGroupInfo(); //获取可选择的所有队伍
    this.getGroupList(); //获取我创建的队伍
  },
  methods: {
    //调整顺序
    setTop(identify_code, index, newOrder, oldOrder) {
      //调用接口
      let params = "";
      //let user_id = identify_code==='1'?that.me_group_list[0].userInfo[index].id:that.me_group_list[0].teaInfo[index].id
      let params_str =
        "?user_id=" +
        index +
        "&identify_code=" +
        identify_code +
        "&order_num=" +
        newOrder +
        "&order_num_old=" +
        oldOrder;
      let that = this;
      this.$http
        .run(topGroup + params_str, params, { type: "get", loginApi: true })
        .then((res) => {
          that.getGroupList();
        });
    },
    //我的队伍信息
    getGroupList() {
      let params = "";
      let that = this;
      this.$http
        .run(getMyGroupList, params, { type: "get", loginApi: true })
        .then((res) => {
          //console.log(res);
          that.me_group_list = res.data;
          if (that.me_group_list.length == 0) {
            that.isCanCreate = true;
          } else {
            that.isCanCreate = false;
          }
        });
    },
    sure_info() {
      //
      let params = "";
      let that = this;
      this.$http
        .run(
          sureGroup + "?group_code=" + that.me_group_list[0].group_pwd,
          params,
          { type: "get", loginApi: true }
        )
        .then((res) => {
          //console.log(res);
          that.getGroupList();
          that.dialogVisible1 = false;
        });
    },
    //创建队伍
    submitCreateGroup() {
      this.dialogFormVisible = false;
      let group_name = this.formCreateGroup.group_name;
      let group_type = this.formCreateGroup.group_type;
      if (!group_name || !group_type) {
        this.$message({
          message: "请完整填写队伍名称和队伍组别！",
          type: "error",
        });
        return;
      }
      let params = "";
      let that = this;
      this.$http
        .run(
          reportCreateGroup +
            "?group_name=" +
            group_name +
            "&group_type=" +
            group_type,
          params,
          { type: "get", loginApi: true }
        )
        .then((res) => {
          that.getGroupList();
          that.$message({ message: "队伍创建成功！", type: "success" });
        });
    },
    buildNewTeam() {
      this.dialogFormVisible = true;
    },

    /**
     * 获取身份信息
     */
    getIdentifyInfo() {
      let that = this;
      let params = "";
      this.$http
        .run(identify, params, { type: "get", loginApi: true })
        .then((res) => {
          //console.log(res);
          that.identify_role = res.data.ident_type;
          that.is_report_success = res.data.isReport;
        });
    },
    /**
     * 获取队伍列表
     */
    getGroupInfo() {
      let that = this;
      let params = "";
      this.$http
        .run(allGroupList, params, { type: "get", loginApi: true })
        .then((res) => {
          that.groupList = [];
          for (let index = 0; index < res.data.length; index++) {
            that.groupList.push(res.data[index]);
          }
          // console.dir(res.data);
          // console.dir(that.groupList);
        });
    },
    /**
     * 获取参赛信息
     */
    queryEntryMsg() {
      let that = this;
      this.$http
        .run(reportInfoReq, "", { type: "get", loginApi: true })
        .then((res) => {
          that.report_info = res.data;
        });
    },
    /**
     * 报名
     */
    signUpBtn() {
      let that = this;
      //this.$message({ message: this.sel_group_name, type: "success" });
      this.$http
        .run(reportReq + "?group_id=" + that.sel_group_name, "", {
          type: "get",
          loginApi: true,
        })
        .then((res) => {
          // console.log(res.code);
          this.$message({ message: "恭喜您报名成功！", type: "success" });
          //获取参赛信息
          that.is_report_success = "1";
          that.queryEntryMsg();
        });
    },
  },
};
</script>

<style scoped>
.person_entry {
  padding-left: 40px;
  text-align: left;
}

.title {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 12px;
}

.person_entry_rach {
  margin-bottom: 12px;
}

.person_entry_table_title {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 8px;
}

.person_entry_rach input {
  /* background: none; */
  outline: none;
  width: 478px;
  height: 38px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #c0c0c0;
  padding: 0;
  text-indent: 16px;
}

.entry_box {
  margin-bottom: 22px;
  padding-right: 20px;
}

.build_team {
  width: 140px;
  height: 40px;
  text-align: center;
  background: linear-gradient(180deg, #4786ff 0%, #4869ff 100%);
  box-shadow: 0px 4px 8px 0px rgba(15, 68, 184, 0.4);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  cursor: pointer;
}

.sign_up_btn {
  width: 140px;
  height: 40px;
  background: linear-gradient(180deg, #4786ff 0%, #4869ff 100%);
  box-shadow: 0px 4px 8px 0px rgba(15, 68, 184, 0.4);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
</style>
