<template>
  <div class="person_entry">
    <!-- 报名 -->

    <!-- 学生身份 报名成功了 -->
    <div class="entry_box" v-if="is_report_success === '1' && identifi === '1'">
      <div class="title">报名信息</div>
      <div class="person_entry_rach">
        <div class="person_entry_table_title">
          姓名：{{ report_info.userInfo.ky_user_info.real_name }} |
          {{ report_info.userType }}
          <label style="color: green">报名成功</label>
        </div>
      </div>
      <div class="person_entry_rach">
        <div class="person_entry_table_title">
          学号：{{ report_info.userInfo.ky_user_info.student_code }}
        </div>
      </div>
      <div class="person_entry_rach">
        <div class="person_entry_table_title">
          学校：{{ report_info.userInfo.ky_user_info.school_name }}
        </div>
      </div>
      <div class="person_entry_rach">
        <div class="person_entry_table_title">
          年级：{{ report_info.userInfo.ky_user_info.grade_name }}
        </div>
      </div>
      <div class="person_entry_rach">
        <div class="person_entry_table_title">
          队伍名称：{{ report_info.groupInfo.group_name }}
        </div>
      </div>
      <div class="person_entry_rach">
        <div class="person_entry_table_title">
          报名号码：{{ report_info.groupInfo.group_pwd }}
        </div>
      </div>
      <div class="person_entry_rach">
        <div class="person_entry_table_title">
          队伍创建者：{{ report_info.groupInfo.teacher.ky_user_info.real_name }}
        </div>
      </div>
      <div class="person_entry_rach">
        <div class="person_entry_table_title">
          队伍创建者联系方式：{{
            report_info.groupInfo.teacher.ky_user_info.phone
          }}/{{ report_info.groupInfo.teacher.ky_user_info.email }}
        </div>
      </div>
    </div>

    <!-- 老师身份 报名成功了   v-if="is_report_success === '1'&& identifi==='2'" -->
    <div class="entry_box" v-if="is_report_success === '1' && identifi === '2'">
      <div class="title">报名信息</div>
      <hr />
      <div v-for="(item, idx) in report_info" :key="idx">
        <div class="person_entry_rach">
          <div class="person_entry_table_title">
            姓名：{{ item.userInfo.ky_user_info.real_name }} |
            {{ item.userType }}
            <label style="color: green">报名成功</label>
          </div>
        </div>
        <div class="person_entry_rach">
          <div class="person_entry_table_title">
            学号：{{ item.userInfo.ky_user_info.student_code }}
          </div>
        </div>
        <div class="person_entry_rach">
          <div class="person_entry_table_title">
            学校：{{ item.userInfo.ky_user_info.school_name }}
          </div>
        </div>
        <div class="person_entry_rach">
          <div class="person_entry_table_title">
            年级：{{ item.userInfo.ky_user_info.grade_name }}
          </div>
        </div>
        <div class="person_entry_rach">
          <div class="person_entry_table_title">
            队伍名称：{{ item.groupInfo.group_name }}
          </div>
        </div>
        <div class="person_entry_rach">
          <div class="person_entry_table_title">
            报名号码：{{ item.groupInfo.group_pwd }}
          </div>
        </div>
        <div class="person_entry_rach">
          <div class="person_entry_table_title">
            队伍创建者：{{ item.groupInfo.teacher.ky_user_info.real_name }}
          </div>
        </div>
        <div class="person_entry_rach">
          <div class="person_entry_table_title">
            队伍创建者联系方式：{{
              item.groupInfo.teacher.ky_user_info.phone
            }}/{{ item.groupInfo.teacher.ky_user_info.email }}
          </div>
        </div>
        <hr />
      </div>
    </div>

    <!-- 学生身份 未报名成功 老师的话一直显示、学生只显示未报名-->
    <div class="entry_box" v-if="is_report_success === '0' || identifi === '2'">
      <div class="title">报名</div>
      <el-form :label-position="'left'" :model="groupFormReport">
        <el-form-item label="报名编码">
          <el-input
            type="text"
            style="width: 120px"
            v-model="sel_group_name"
            autocomplete="off"
            placeholder="请输入队伍编号"
          ></el-input>
        </el-form-item>

        <!-- <el-form-item label="身份类型">
          <el-select
            v-model="sel_group_name_2"
            style="width: 120px"
            placeholder="请选择身份"
          >
            <el-option
              v-for="item in ident_info"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div class="sign_up_btn" @click="signUpBtn">提交报名申请</div>
      <br />
      <div v-show="is_report_success === '0' || identifi === '1'">
        参赛队员只能报名一次，点击确认前请与队伍创建者确认队伍编号的正确性
      </div>
    </div>
  </div>
</template>

<script>
import {
  allGroupList,
  identify,
  reportReq,
  reportInfoReq,
  reportCreateGroup,
  getMyGroupList,
} from "../../../util/http/api";

export default {
  data() {
    return {
      identify_role: "0", // 0游客  1老师  2学生  身份
      is_report_success: "0", // 1是 0否 报名成功
      formLabelAlign: {},
      dialogFormVisible: false, //创建队伍弹窗
      formLabelWidth: "80px",
      formCreateGroup: {
        group_name: "",
        group_type: "初中组",
      },
      //报名选队伍
      groupList: [],
      sel_group_name: "", //报名号
      //sel_group_name_2: "", //身份
      identifi: "1", //默认学生
      groupFormReport: {
        group_name: "",
        group_type: "",
        id: 0,
        teacher_id: 0,
        teacher_name: "",
        school_name: "",
      },
      //报名信息
      report_info: {},
      me_group_list: [],
    };
  },
  mounted() {
    this.getIdentify();
  },
  created() {
    this.getIdentifyInfo(); //获取身份信息
    this.queryEntryMsg(); //获取报名信息
    this.getGroupInfo(); //获取可选择的所有队伍
    this.getGroupList(); //获取我创建的队伍
  },
  methods: {
    //获取身份
    getIdentify() {
      let userInfo = localStorage.getItem("ky_login_info");
      userInfo = JSON.parse(userInfo);
      // console.log("!!!!!!!!!!!!!!!!");
      // console.dir(userInfo);
      this.identifi = userInfo.profile.role.indexOf("teacher") > -1 ? "2" : "1";
      //console.log("!!!!!"+this.identifi);
    },
    //我的队伍信息
    getGroupList() {
      let params = "";
      let that = this;
      this.$http
        .run(getMyGroupList, params, { type: "get", loginApi: true })
        .then((res) => {
          // console.log(res);
          that.me_group_list = res.data;
        });
    },
    //创建队伍
    submitCreateGroup() {
      this.dialogFormVisible = false;
      let group_name = this.formCreateGroup.group_name;
      let group_type = this.formCreateGroup.group_type;
      if (!group_name || !group_type) {
        this.$message({
          message: "请完整填写队伍名称和队伍组别！",
          type: "error",
        });
        return;
      }
      let params = "";
      let that = this;
      this.$http
        .run(
          reportCreateGroup +
            "?group_name=" +
            group_name +
            "&group_type=" +
            group_type,
          params,
          { type: "get", loginApi: true }
        )
        .then((res) => {
          //console.log(res);
          that.getGroupList();
          that.$message({ message: "队伍创建成功！", type: "success" });
        });
    },
    buildNewTeam() {
      this.dialogFormVisible = true;
    },

    /**
     * 获取身份信息
     */
    getIdentifyInfo() {
      let that = this;
      let params = "";
      this.$http
        .run(identify, params, { type: "get", loginApi: true })
        .then((res) => {
          //console.log(res);
          that.identify_role = res.data.ident_type;
          that.is_report_success = res.data.isReport;
        });
    },
    /**
     * 获取队伍列表
     */
    getGroupInfo() {
      let that = this;
      let params = "";
      this.$http
        .run(allGroupList, params, { type: "get", loginApi: true })
        .then((res) => {
          that.groupList = [];
          for (let index = 0; index < res.data.length; index++) {
            that.groupList.push(res.data[index]);
          }
          //console.dir(res.data);
          //console.dir(that.groupList);
        });
    },
    /**
     * 获取参赛信息
     */
    queryEntryMsg() {
      let that = this;
      this.$http
        .run(reportInfoReq, "", { type: "get", loginApi: true })
        .then((res) => {
          console.dir(res.data);
          that.report_info = res.data;
        });
    },
    /**
     * 报名
     */
    signUpBtn() {
      let that = this;
      //this.$message({ message: this.sel_group_name, type: "success" });
      this.$http
        .run(
          reportReq +
            "?group_id=" +
            that.sel_group_name +
            "&identify_code=" +
            that.identifi,
          "",
          {
            type: "get",
            loginApi: true,
          }
        )
        .then((res) => {
          //console.log(res.code);
          this.$message({ message: "恭喜您报名成功！", type: "success" });
          //获取参赛信息
          that.is_report_success = "1";
          that.queryEntryMsg();
        });
    },
  },
};
</script>

<style scoped>
.person_entry {
  padding-left: 40px;
  text-align: left;
}

.title {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 12px;
}

.person_entry_rach {
  margin-bottom: 12px;
}

.person_entry_table_title {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 8px;
}

.person_entry_rach input {
  /* background: none; */
  outline: none;
  width: 478px;
  height: 38px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #c0c0c0;
  padding: 0;
  text-indent: 16px;
}

.entry_box {
  margin-bottom: 22px;
  padding-right: 20px;
}

.build_team {
  width: 140px;
  height: 40px;
  text-align: center;
  background: linear-gradient(180deg, #4786ff 0%, #4869ff 100%);
  box-shadow: 0px 4px 8px 0px rgba(15, 68, 184, 0.4);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  cursor: pointer;
}

.sign_up_btn {
  width: 140px;
  height: 40px;
  background: linear-gradient(180deg, #4786ff 0%, #4869ff 100%);
  box-shadow: 0px 4px 8px 0px rgba(15, 68, 184, 0.4);
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
</style>
