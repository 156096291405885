<template>
  <!-- 赛场宽度固定  换算比 1117/126 -->
  <div>
    <div
      :id="`${canvasId}div`"
      class="play_area_div"
      :class="canvasId"
      ref="play_area_ref"
    >
      <canvas :id="canvasId" width="0" height="0"></canvas>
    </div>
    <div
      :id="`${canvasId}divBig`"
      class="play_area_div"
      :class="`${canvasId}Big`"
      ref="play_area_refBig"
    >
      <canvas :id="`${canvasId}Big`" width="0" height="0"></canvas>
    </div>
  </div>
</template>

<script>
////冰壶相关定义///////开始//////////////////////////////////////////////////////////////
const $ = (str) => {
  return document.getElementById(str);
};
Array.prototype.remove = function (dx) {
  if (isNaN(dx) || dx > this.length) {
    return false;
  }
  for (var i = 0, n = 0; i < this.length; i++) {
    if (this[i] !== this[dx]) {
      this[n++] = this[i];
    }
  }
  this.length -= 1;
};
////冰壶相关定义///////结束//////////////////////////////////////////////////////////////

const PIXEL_TO_INCH = 2.7; // 英寸与像素的转换
const PIXEL_INCH_SPEED_RATE = 11.92765464; // 英寸/秒与像素/秒的速度转换

//英寸转像素
Number.prototype.toPixel = function () {
  return this / PIXEL_TO_INCH;
};
Number.prototype.toPixelSpeed = function () {
  return this / PIXEL_TO_INCH / PIXEL_INCH_SPEED_RATE; //11.980625; //11.927516007223797;
};
Number.prototype.toInch = function () {
  return this * PIXEL_TO_INCH;
};

//所有单位使用像素
const TOTAL_WIDTH = 560; // 球场宽度
const TOTAL_HEIGHT = 63; // 球场高度
const BALL_R = Number(6).toPixel(); // 球半径
const BALL_BORDER = 1; // 球边缘厚度
const RATE = 100; // 刷新频率，数字越大速度越快
const F = 0.02; // 摩擦力
const ZOOM = 5.91; // 缩放到大图视角
const ZOOM_OFFSET_X = 448; // 缩放后便宜位置
const OFFSET_X0 = 506.6666666666666; // 坐标系原点在UI上的像素位置X
const OFFSET_Y0 = 31.48148148148148; // 坐标系原点在UI上的像素位置Y

export default {
  data() {
    return {
      /*碰撞前所有球的数组*/
      balls_after: [],
      /*所有球的数组*/
      balls: [],
      bigBalls: [], // 大球
      /*当前移动球的下标*/
      movingBallIdx: 0,
      /*正在移动中的球*/
      movingBalls: [],

      throwingBall: null, //本次抛出球
      hasImpact: false, //是否碰撞过
    };
  },
  props: {
    canvasId: {
      type: String,
      default: "myCanvasPlay",
    },
    proporting: {
      type: Number,
      default: 1,
    },
    fat_div_width: {
      //赛场画布的宽度
      type: Number,
      default: 1024,
    },
    fat_div_height: {
      //赛场画布的高度
      type: Number,
      default: 115.132,
    },
    // 球全部停止时的回调函数
    allBallsStopCallBack: {
      type: Function,
      require: false,
    },
  },
  methods: {
    //画上面小图的背景
    intCanvasMap() {
      //获取画布对象
      var c = document.getElementById(this.canvasId);
      // c.width = TOTAL_WIDTH; //调整画布大小
      // c.height = TOTAL_HEIGHT;

      c.setAttribute("width", TOTAL_WIDTH + "px");
      c.setAttribute("height", TOTAL_HEIGHT + "px");

      var ctx = c.getContext("2d");
      ctx.clearRect(0, 0, c.width, c.height);

      //上下边线
      ctx.fillStyle = "#062BAC";
      ctx.fillRect(0, 0, TOTAL_WIDTH, 2);
      ctx.fillRect(0, TOTAL_HEIGHT - 2, TOTAL_WIDTH, 2);

      ctx.fillStyle = "#AAAAAA";
      ctx.fillRect(0, TOTAL_HEIGHT / 2 - 1, TOTAL_WIDTH, 2);

      ctx.fillRect(TOTAL_WIDTH - (144).toPixel(), 2, 2, TOTAL_HEIGHT - 4);
      ctx.fillStyle = "#FF5500";
      ctx.fillRect(TOTAL_WIDTH - (396).toPixel(), 2, 2, TOTAL_HEIGHT - 4);
      ctx.fillRect((252).toPixel(), 2, 2, TOTAL_HEIGHT - 4);
      ctx.fillStyle = "#AAAAAA";
      ctx.fillRect(
        (TOTAL_WIDTH - (144).toPixel()) / 2,
        TOTAL_HEIGHT / 2 - 20,
        2,
        20
      );
      ctx.stroke();
      ctx.closePath();

      //画大本营
      var pointX = (144).toPixel();
      var pointY = TOTAL_HEIGHT / 2;
      //缩放比例
      var radis1 = (72).toPixel();
      var radis2 = (48).toPixel();
      var radis3 = (24).toPixel();
      var radis4 = (6).toPixel();

      ctx.lineWidth = 1;

      // console.log(this.pageBuScale)
      // console.log("半径===="+radis1+"=="+radis2+"=="+radis3+"=="+radis4);
      // console.log("半径===="+radis1/this.pageBuScale+"=="+radis2/this.pageBuScale+"=="+radis3/this.pageBuScale+"=="+radis4/this.pageBuScale);
      // let wuchazhi = 0; //误差值
      // if (this.canvasId === "myCanvasPlayB") {
      //   wuchazhi = 3;
      // } else {
      //   wuchazhi = 1;
      // }

      //画一个实心圆
      ctx.beginPath();
      ctx.arc(TOTAL_WIDTH - pointX, pointY, radis1, 0, Math.PI * 2, false);
      ctx.arc(0, pointY, radis1, 0, Math.PI * 2, false);
      ctx.fillStyle = "#062BAC"; //填充颜色,默认是黑色
      ctx.fill(); //画实心圆
      ctx.closePath();
      //画一个实心圆
      ctx.beginPath();
      ctx.arc(TOTAL_WIDTH - pointX, pointY, radis2, 0, Math.PI * 2, false);
      ctx.arc(0, pointY, radis2, 0, Math.PI * 2, false);
      ctx.fillStyle = "#CDD2D4"; //填充颜色,默认是黑色
      ctx.fill(); //画实心圆
      ctx.closePath();
      //画一个实心圆
      ctx.beginPath();
      ctx.arc(TOTAL_WIDTH - pointX, pointY, radis3, 0, Math.PI * 2, false);
      ctx.arc(0, pointY, radis3, 0, Math.PI * 2, false);
      ctx.fillStyle = "#C10D15"; //填充颜色,默认是黑色
      ctx.fill(); //画实心圆
      ctx.closePath();
      //画一个实心圆
      ctx.beginPath();
      ctx.arc(TOTAL_WIDTH - pointX, pointY, radis4, 0, Math.PI * 2, false);
      ctx.arc(0, pointY, radis4, 0, Math.PI * 2, false);
      ctx.fillStyle = "#CDD2D4"; //填充颜色,默认是黑色
      ctx.fill(); //画实心圆
      ctx.closePath();

      // 画大背景
      this.initCanvasMapBig();
    },

    //画下面大图的背景
    initCanvasMapBig() {
      const offsetX = 448;
      //获取画布对象
      var c = document.getElementById(this.canvasId + "Big");
      // c.width = TOTAL_WIDTH; //调整画布大小
      // c.height = TOTAL_HEIGHT;

      c.setAttribute("width", TOTAL_WIDTH + "px");
      c.setAttribute("height", TOTAL_HEIGHT * ZOOM + "px");
      var ctx = c.getContext("2d");
      // ctx.scale(ZOOM, ZOOM);
      ctx.clearRect(0, 0, c.width, c.height);

      //上下边线
      ctx.fillStyle = "#062BAC";
      ctx.fillRect(0, 0, TOTAL_WIDTH, 2);
      ctx.fillRect(0, TOTAL_HEIGHT * ZOOM - 2, TOTAL_WIDTH, 2);
      ctx.fillStyle = "#AAAAAA";
      ctx.fillRect(0, (TOTAL_HEIGHT * ZOOM) / 2 - 1, TOTAL_WIDTH, 2);

      var virtualWidth = TOTAL_WIDTH * ZOOM;
      var virtualHeight = TOTAL_HEIGHT * ZOOM;

      ctx.fillRect(
        (TOTAL_WIDTH - (144).toPixel() - offsetX) * ZOOM,
        2,
        2,
        TOTAL_HEIGHT * ZOOM - 4
      );

      ctx.fillStyle = "#FF5500";
      ctx.fillRect(
        (TOTAL_WIDTH - (396).toPixel() - offsetX) * ZOOM,
        2,
        2,
        TOTAL_HEIGHT * ZOOM - 4
      );

      ctx.fillRect(
        ((252).toPixel() - offsetX) * ZOOM,
        2,
        2,
        TOTAL_HEIGHT * ZOOM - 4
      );

      ctx.fillStyle = "#AAAAAA";
      ctx.fillRect(
        ((TOTAL_WIDTH - (144).toPixel()) / 2 - offsetX) * ZOOM,
        (TOTAL_HEIGHT / 2 - 20) * ZOOM,
        2,
        20 * ZOOM
      );
      ctx.stroke();
      ctx.closePath();

      //画大本营
      var pointX = (144).toPixel();
      var pointY = (TOTAL_HEIGHT * ZOOM) / 2;
      //缩放比例
      var radis1 = (72).toPixel();
      var radis2 = (48).toPixel();
      var radis3 = (24).toPixel();
      var radis4 = (6).toPixel();
      ctx.lineWidth = 1;

      //画一个实心圆
      ctx.beginPath();
      ctx.arc(
        (TOTAL_WIDTH - pointX - offsetX) * ZOOM,
        pointY,
        radis1 * ZOOM,
        0,
        Math.PI * 2,
        false
      );

      // ctx.arc(0, pointY, radis1, 0, Math.PI * 2, false);

      ctx.fillStyle = "#062BAC"; //填充颜色,默认是黑色
      ctx.fill(); //画实心圆
      ctx.closePath();

      //画一个实心圆
      ctx.beginPath();
      ctx.arc(
        (TOTAL_WIDTH - pointX - offsetX) * ZOOM,
        pointY,
        radis2 * ZOOM,
        0,
        Math.PI * 2,
        false
      );
      // ctx.arc(0, pointY, radis2, 0, Math.PI * 2, false);
      ctx.fillStyle = "#CDD2D4"; //填充颜色,默认是黑色
      ctx.fill(); //画实心圆
      ctx.closePath();

      // 画一个实心圆
      ctx.beginPath();
      ctx.arc(
        (TOTAL_WIDTH - pointX - offsetX) * ZOOM,
        pointY,
        radis3 * ZOOM,
        0,
        Math.PI * 2,
        false
      );

      // ctx.arc(0, pointY, radis3, 0, Math.PI * 2, false);
      ctx.fillStyle = "#C10D15"; //填充颜色,默认是黑色
      ctx.fill(); //画实心圆
      ctx.closePath();
      //画一个实心圆
      ctx.beginPath();
      ctx.arc(
        (TOTAL_WIDTH - pointX - offsetX) * ZOOM,
        pointY,
        radis4 * ZOOM,
        0,
        Math.PI * 2,
        false
      );
      // ctx.arc(0, pointY, radis4, 0, Math.PI * 2, false);
      ctx.fillStyle = "#CDD2D4"; //填充颜色,默认是黑色
      ctx.fill(); //画实心圆
      ctx.closePath();
    },

    /**
     * 小球的属性
     * @param type 小球的类名
     * @param x 小球的x轴坐标
     * @param y 小球的y轴坐标
     * @param typeVal 小球的类型 red yellow
     */
    Ball(classname, x, y, typeVal, stu_name, that, isBig) {
      let ball = {};
      // let table = $(`"play_area_div"+"div"`);
      let table = document.getElementById(that.canvasId + "div");
      if (isBig) table = document.getElementById(that.canvasId + "divBig");
      let div = document.createElement("div");
      let color = typeVal === "red" ? "  ball" : "  ball_y";
      let size =
        that.canvasId === "myCanvasPlayA" ? "  ball_size_1" : "  ball_size_2";
      if (isBig) size = " ball_size_2";
      div.className = classname + color + size;

      if (that.canvasId === "myCanvasPlayB") {
        if (!isBig) {
          div.style.width = (12).toPixel() + "px";
          div.style.height = (12).toPixel() + "px";
        } else {
          div.style.width = (12).toPixel() * ZOOM + "px";
          div.style.height = (12).toPixel() * ZOOM + "px";
        }
      } else {
        if (!isBig) {
          div.style.width = (12).toPixel() + "px";
          div.style.height = (12).toPixel() + "px";
        } else {
          div.style.width = (12).toPixel() * ZOOM + "px";
          div.style.height = (12).toPixel() * ZOOM + "px";
        }
      }

      ball.elem = table.appendChild(div);
      ball.type = classname;
      ball.ball_name = classname;
      ball.stu_name = stu_name;
      ball.ball_type = typeVal;
      ball.x = x; //位置
      ball.y = y;

      // 初始化球的起点

      ball.angle = 0; //角度
      ball.v = 0; //速度(不包含方向)
      return ball;
    },

    //初始化一个小球
    initBall(name, x, y, typeVal, stu_name) {
      let cueBall = new this.Ball(name, x, y, typeVal, stu_name, this);
      this.balls.push(cueBall);
      let bigCueBall = new this.Ball(name, x, y, typeVal, stu_name, this, true);
      this.bigBalls.push(bigCueBall);
      cueBall.bigBall = bigCueBall;
    },

    ///抛出球开始运动
    shotCueBall(speed, angle) {
      //console.log("shotCueBall(" + speed + "," + angle + ")");
      // 设置球移动
      let cueBalls = this.balls[this.moveBallIdx];
      //console.log("balls[0]:" + this.balls[0].x + "," + this.balls[0].y + "," + this.balls[0].v);
      //console.log("cueBalls:" + cueBalls.x + "," + cueBalls.y + "," + cueBalls.v);
      cueBalls.v = speed;
      cueBalls.angle = (angle * Math.PI) / 180; //角度转换成弧度   角度 * Math.PI / 180
      //console.log("cueBalls:" + cueBalls.x + "," + cueBalls.y + "," + cueBalls.v);
      this.movingBalls.push(cueBalls);
      let i = 0;
      //console.log(cueBalls.x);
      this.timer = setInterval(() => {
        this.roll();
        i++;
        // console.log(i);
        // console.log(cueBalls.x);
      }, 1000 / RATE); //已固定频率刷新
    },

    //滚动函数
    roll() {
      // console.log("roll()");

      // 球移动过程
      let balls = this.balls;
      // console.log("balls[0]:" + this.balls[0].x + "," + this.balls[0].y + "," + this.balls[0].v);
      let movingBalls = this.movingBalls;
      // console.log("movingBalls[0]:" + this.movingBalls[0].x + "," + this.movingBalls[0].y + "," + this.movingBalls[0].v);
      let R = BALL_R;
      let H = TOTAL_HEIGHT;
      let W = TOTAL_WIDTH;
      // console.log('a');
      // console.log(balls[0]);
      //所有的球都静止后停止刷新
      if (movingBalls.length <= 0) {
        window.clearInterval(this.timer);
        if (this.$props.allBallsStopCallBack)
          this.$props.allBallsStopCallBack(
            balls,
            this.throwingBall,
            this.hasImpact
          );
      }
      //循环计算移动
      for (let i = 0; i < movingBalls.length; i++) {
        let ball = movingBalls[i],
          sin = Math.sin(ball.angle),
          cos = Math.cos(ball.angle);
        // console.log("sin:" + sin + ", cos:" + cos);

        let oldV = ball.v;
        ball.v -= F;

        if (ball.v < 0) ball.v = 0;

        // 移除静止的小球
        if (ball.v === 0) {
          //角度分量上的速度
          let vx = oldV * sin,
            vy = oldV * cos;
          // console.log("vx:" + vx + ", vy:" + vy);
          ball.x += vx;
          ball.y += vy;
          // if (ball.v * 100.0 / 100.0 <= 0.2) {
          ball.v = 0;
          movingBalls.remove(i);
          continue;
        }

        //角度分量上的速度
        let vx = ball.v * sin,
          vy = ball.v * cos;
        // console.log("vx:" + vx + ", vy:" + vy);
        ball.x += vx;
        ball.y += vy;

        //场地边缘碰撞检测
        if (ball.x < R || ball.x > W - R) {
          this.hide(ball.elem);
          movingBalls.remove(i); //出界球
        }
        if (ball.y < R || ball.y > H - R) {
          this.hide(ball.elem);
          movingBalls.remove(i); //出界球
        }

        // console.log("balls[0]:" + this.balls[0].x + "," + this.balls[0].y + "," + this.balls[0].v);
        // console.log("movingBalls[0]:" + movingBalls[0].x + "," + movingBalls[0].y + "," + movingBalls[0].v);

        //小球碰撞
        for (let j = 0; j < balls.length; j++) {
          let obj = balls[j];
          if (obj === ball) continue;
          let disX = obj.x - ball.x,
            disY = obj.y - ball.y,
            gap = 2 * R;
            //console.log(disX+"##"+disY);
          if (disX<= gap && Math.abs(disY) <= gap) {
            let oldX = ball.x,
              oldY = ball.y;
            let dis = Math.sqrt(Math.pow(disX, 2) + Math.pow(disY, 2));
            if (dis <= gap) {
              this.hasImpact = true;
              //发生碰撞
              //console.log("碰撞前：" + ball.x + "," + ball.v + " | " + obj.x + "," + obj.v);

              let A = (gap - dis) * sin * 1;
              let B = ball.v;

              let oldVX = (vx * A) / B;
              let oldVY = (vy * A) / B;

              /*
            dis=4.3752228668215025, gap=4.447058823529412
            before ball.x=502.8546488369913, ball.y=31.5
            after ball.x=502.7828128802834, ball.y=31.5
            ball.x=502.7828128802834, ball.y=31.5
            */
              // console.log("v0=" + (ball.v+F) + "v1=" + ball.v);

              // console.log("dis=" + dis + ", gap=" + gap);
              //如果是静止的，则添加到数组movingBalls
              // if (Math.round(obj.v * 100) / 100 <= 0.001) movingBalls.push(obj);
              if (obj.v <= 0) movingBalls.push(obj);
              //还原两球相切状态 - 近似值
              // console.log("before ball.x=" + ball.x + ", ball.y=" + ball.y);

              //退回状态误差调整
              const RETURN_REDUCE_VALUE = 1; //0.98;

              ball.x -= (gap - dis) * sin * RETURN_REDUCE_VALUE;
              ball.y -= (gap - dis) * cos * RETURN_REDUCE_VALUE;

              //console.log("退回后" + ball.x + "," + ball.v + " | " + obj.x + "," + obj.v);
              // console.log("after ball.x=" + ball.x + ", ball.y=" + ball.y);
              disX = obj.x - ball.x;
              disY = obj.y - ball.y;
              // 计算角度和正余弦值
              let angle = Math.atan2(disY, disX),
                hitsin = Math.sin(angle),
                hitcos = Math.cos(angle),
                objVx = obj.v * Math.sin(obj.angle),
                objVy = obj.v * Math.cos(obj.angle);
              // 旋转坐标
              let x1 = 0,
                y1 = 0,
                x2 = disX * hitcos + disY * hitsin,
                y2 = disY * hitcos - disX * hitsin,
                vx1 = oldVX * hitcos + oldVY * hitsin,
                vy1 = oldVY * hitcos - oldVX * hitsin,
                vx2 = objVx * hitcos + objVy * hitsin,
                vy2 = objVy * hitcos - objVx * hitsin;

              // 碰撞后的速度和位置
              let plusVx = vx1 - vx2;
              vx1 = vx2;
              vx2 = plusVx + vx1;
              x1 += vx1;
              x2 += vx2;
              // 将位置旋转回来
              let x1Final = x1 * hitcos - y1 * hitsin,
                y1Final = y1 * hitcos + x1 * hitsin,
                x2Final = x2 * hitcos - y2 * hitsin,
                y2Final = y2 * hitcos + x2 * hitsin;

              // obj.x = ball.x + x2Final; // - ball.v; //0.4118518518518;
              //console.log("x1Final=" + x1Final + ", y1Final=" + y1Final + " | x2Final=" + x2Final + ", y2Final=" + y2Final);
              obj.x = ball.x + x2Final;
              obj.y = ball.y + y2Final;

              ball.x = ball.x + x1Final;
              ball.y = ball.y + y1Final;

              // console.log("521 ball.x=" + ball.x + ", ball.y=" + ball.y);

              // 将速度旋转回来
              oldVX = vx1 * hitcos - vy1 * hitsin;
              oldVY = vy1 * hitcos + vx1 * hitsin;
              objVx = vx2 * hitcos - vy2 * hitsin;
              objVy = vy2 * hitcos + vx2 * hitsin;

              objVx = (objVx * B) / A;
              objVy = (objVy * B) / A;

              //最终速度
              ball.v = Math.sqrt(oldVX * oldVX + oldVX * oldVX);
              obj.v = Math.sqrt(objVx * objVx + objVy * objVy);

              // 0.41179940770545986
              // 0.396656882629815

              //速度衰减误差调整
              const SPEED_REDUCE_VALUE = 1; // 0.999460431654675851;//0.98;
              obj.v = obj.v * SPEED_REDUCE_VALUE;
              //console.log(obj.v);

              // console.log("obj.v=" + obj.v);
              //console.log("碰撞后：" + ball.x + "," + ball.v + " | " + obj.x + "," + obj.v);

              // 计算角度
              ball.angle = Math.atan2(oldVX, oldVY);
              obj.angle = Math.atan2(objVx, objVy);
            }
          }
        }

        this.setBallPos(ball.elem, ball.x, ball.y, ball);
        // console.log(ball);
      }
    },

    //设置球的位置
    setPos(obj, x, y) {
      obj.style.left = x + "px";
      obj.style.top = y + "px";
    },

    //设置球的落点位置
    setBallPos(ballElement, x, y, ball) {
      var posX = x + BALL_BORDER - BALL_R;
      var posY = y + BALL_BORDER - BALL_R;

      // 误差纠偏
      const wuchaX = -0.5,
        wuchaY = -0.8;
      const wuchaXBig = 2,
        wuchaYBig = -1;

      this.setPos(ball.elem, posX + wuchaX, posY + wuchaY);

      //同步移动大球
      var posXBig = Math.round((posX - ZOOM_OFFSET_X) * ZOOM) - 7 + wuchaXBig;
      var posYBig = Math.round(posY * ZOOM) - 4 + wuchaYBig;

      this.setPos(ball.bigBall.elem, posXBig, posYBig);
    },

    setStyle() {
      if (arguments.length === 2 && typeof arguments[1] === "object") {
        for (var key in arguments[1]) {
          arguments[0].style[key] = arguments[1][key];
        }
      } else if (arguments.length > 2) {
        arguments[0].style[arguments[1]] = arguments[2];
      }
    },

    hide(obj) {
      this.setStyle(obj, "display", "none");
    },
    show(obj) {
      this.setStyle(obj, "display", "block");
    },

    startRoll(v, a) {
      // 使球移动
      let speed = v; // 球移动速度
      let angle = a;
      // let speed = 4; // 球移动速度
      // let angle = 80.1
      //console.log("startRoll(" + v + "," + a + ")");
      this.shotCueBall(speed, angle);
    },

    addBall(name, x, y, typeVal, stu_name) {
      this.moveBallIdx++;
      this.initBall(name, x, y, typeVal, stu_name);
    },

    ///设置所有球的位置
    setAllBallRetry(ballList) {
      setTimeout(() => {
        this.balls = [];
        for (let index = 0; index < ballList.length; index++) {
          //单位换算
          let ballItem = ballList[index];
          //创建一个球
          this.addBall(
            this.canvasId + ballItem.ball_name,
            ballItem.ball_x,
            ballItem.ball_y,
            ballItem.ball_type,
            ballItem.stu_name
          );
        }
      }, 1000);
    },

    /**
     * 设置所有球的位置
     */
    setBallsPos(balls) {
      if(!balls || balls.length === 0){
        return;
      }
      balls.forEach(ball => {
        let existingBall = this.balls.find(item => item.stu_name === ball.stu_name);
        if(existingBall){
          existingBall.x = ball.x;
          existingBall.y = ball.y;
        }
        this.setBallPos(existingBall.elem, existingBall.x, existingBall.y, existingBall);
      });
    },

    /**
     * 初始化所有的球[{ball_x,ball_y,ball_name,ball_type,stu_name}]
     */
    initAllBall(ballList) {
      setTimeout(() => {
        this.balls = [];
        this.bigBalls = [];
        for (let index = 0; index < ballList.length; index++) {
          //单位换算
          let ballItem = ballList[index];
          //创建一个球
          this.addBall(
            this.canvasId + ballItem.ball_name,
            ballItem.ball_x,
            ballItem.ball_y,
            ballItem.ball_type,
            ballItem.stu_name
          );
        }

        //console.log("initAllBall()");
        //console.log(this.balls);
      }, 1000);
    },
    /**
     * 将球抛出
     * @param ball_name
     * @param ball_x
     * @param ball_y
     * @param ball_speed
     * @param ball_angel
     */
    throwBallItem(
      ball_name,
      ball_x_pos,
      ball_y_pos,
      ball_speed_inch,
      ball_angel
    ) {
      this.throwingBall = null;
      this.hasImpact = false;

      // if (this.canvasId === "myCanvasPlayB") {
      //   this.balls_after = this.balls; //抛球前记录上次位置
      // }
      //console.log("throwBallItem(" + ball_name + "," + ball_x_pos + "," + ball_y_pos + "," + ball_speed_inch + "," + ball_angel + ")");

      ball_name = this.canvasId + ball_name;
      //单位换算
      ball_angel = ball_angel + 90; //重新规整角度
      let ball_x = ball_x_pos.toPixel(); //重新规整y坐标
      let ball_y = ball_y_pos.toPixel(); //重新规整y坐标
      ///重新计算球的速度   2ugs=初速度²-末速度²   左边打到右边  v = sqrt(2ugs)   s = v*v/2ug
      // ball_speed = (4.230264 / 145.301) * ball_speed;
      let ball_speed = ball_speed_inch.toPixelSpeed(); //(4.2279815 / 145.301) * ball_speed_inch;
      // console.log("扔出球：" + ball_x + "," + ball_y + "," + ball_speed);
      // console.log(ball_speed);
      // ball_speed = 53.81518518518519;

      // ball_speed =
      //   this.canvasId === "myCanvasPlayB"
      //     ? Math.sqrt(this.fat_div_width / 1024) * (ball_speed + 0.063)
      //     : Math.sqrt(this.fat_div_width / 1024) * ball_speed;
      //找到那只球
      for (let index = 0; index < this.balls.length; index++) {
        let ballItem = this.balls[index];
        if (ball_name === ballItem.ball_name) {
          this.throwingBall = ballItem;

          //重新设置球的位置，然后显示出来
          this.balls[index].x = ball_x;
          this.balls[index].y = ball_y;
          //设置移动球的下标
          this.moveBallIdx = index;
          this.startRoll(ball_speed, ball_angel);
        }
      }
    },
  },
  created() {},
  mounted() {
    setTimeout(() => {
      // TOTAL_WIDTH = this.$refs.play_area_ref.offsetWidth;
      // TOTAL_HEIGHT = this.$refs.play_area_ref.offsetHeight;
      // TOTAL_HEIGHT = 63;
      // this.pageBuScale = TOTAL_HEIGHT / 170;
      //设置赛场一些参数
      // this.R = 6 * (TOTAL_HEIGHT / 170);
      // this.H = 170 * (TOTAL_HEIGHT / 170);
      // this.W = TOTAL_WIDTH;
      // this.TOTALR = 6 * (TOTAL_HEIGHT / 170);
      // this.speed = 0;
      // this.pageBuDabenyingPorin.x = TOTAL_WIDTH - (144).toPixel();
      // this.pageBuDabenyingPorin.y = this.H / 2;
      //初始化比赛场地地图
      this.intCanvasMap();
    }, 500);
  },
};
</script>

<style>
.play_area_div {
  width: 100%;
  height: 100%;
  background: #cdd2d4;
  position: relative;
}
.play_area_div canvas {
  width: 100%;
  height: 100%;
}
h1 {
  font-size: 12px;
  color: gray;
  font-weight: normal;
  line-height: 200%;
}
h1 .sub {
  vertical-align: super;
  color: red;
  font-size: 9px;
}
.info {
  position: absolute;
  right: 0;
}

.ball_size_1 {
  width: 8.87px;
  height: 8.87px;
}
.ball_size_2 {
  /*width: 23px;
  height: 23px;*/
  width: 27px;
  height: 27px;
}

.ball {
  position: absolute;
}
.cue,
.ball {
  background-image: url("/img/bh_red_tw.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  _background: none;
  _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(enabled='true', sizingMethod='scale', src="white_ball.png");
}
.ball_y {
  position: absolute;
}
.cue,
.ball_y {
  background-image: url("/img/bh_yellow_tw.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  _background: none;
  _filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(enabled='true', sizingMethod='scale', src="white_ball.png");
}
.bot {
  position: relative;
  width: 800px;
  margin: 10px auto 10px;
  height: 70px;
}
</style>
