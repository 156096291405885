<template>
  <div class="practiceAndmatch">
    <div class="title">
      {{ practiceAndmatchMsg.type === "practice" ? "我的练习" : "我的比赛" }}
    </div>

    <!--训练记录-->
    <div v-if="practiceAndmatchMsg.type === 'practice'">
      <div v-for="(item, index) in trainList" :key="index">
        <div class="practiceAndmatch_node">
          {{ item.train_time }}
        </div>
        <div class="practiceAndmatch_msg">
          <div class="practiceAndmatch_each">
            {{ item.train_time_long }}
          </div>
        </div>
      </div>
    </div>

    <!--比赛记录-->
    <div v-else>
      <div v-for="(item, index) in matchList" :key="index">
        <div class="practiceAndmatch_node">
          {{
            item.match_type +
            ":" +
            item.group_small_name +
            "    比赛编号：" +
            item.code
          }}
        </div>
        <div class="practiceAndmatch_msg">
          <div class="practiceAndmatch_each1">
            {{ item.group_a_name + " VS " + item.group_b_name }}
          </div>
        </div>
        <div class="practiceAndmatch_msg">
          <div class="practiceAndmatch_each">
            {{ item.match_time }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { matchListGet, trainListGet } from "../../../util/http/api";

export default {
  props: {
    practiceAndmatchMsg: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      trainList: [],
      matchList: [],
    };
  },
  mounted() {
    if (this.practiceAndmatchMsg.type === "practice") {
      this.getHttpTrainList();
    } else {
      this.getHttpMatchList();
    }
  },
  methods: {
    //获取训练记录
    getHttpTrainList() {
      let that = this;
      this.$http
        .run(trainListGet, "", { type: "get", loginApi: true })
        .then((res) => {
          // console.log(res);
          that.trainList = res.data;
        });
    },
    //获取比赛记录
    getHttpMatchList() {
      let that = this;
      this.$http
        .run(matchListGet, "", { type: "get", loginApi: true })
        .then((res) => {
          // console.log(res);
          that.matchList = res.data;
        });
    },
  },
};
</script>

<style scoped>
.practiceAndmatch {
  padding-left: 40px;
  text-align: left;
}

.title {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 24px;
}

.practiceAndmatch_node {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  line-height: 20px;
  margin-bottom: 8px;
}

.practiceAndmatch_each {
  height: 50px;
  border-bottom: 1px solid #e9e9e9;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 50px;
}
.practiceAndmatch_each1 {
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 50px;
}
</style>
