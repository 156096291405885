<template>
  <div
    class="throw-area"
    :class="[!isMatcher ? 'maoboli' : !isGroup ? 'maoboli' : '']"
  >
    <div class="player-list-wrap">
      <div class="item" v-for="(item, index) in playerNameList" :key="index">
        <img :src="index == active ? img : img_dis" class="img" alt="" />
        <div>选手{{ index + 1 }}:<br />{{ item }}</div>
      </div>
    </div>

    <div class="send_btn" @click="clickBtn">确认编码</div>
    <div class="mask" v-show="!isMatcher ? true : !isGroup ? true : ''"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    isMatcher: {
      type: Boolean,
      default: false,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    img: {
      type: String,
      default: "img/bh_red.png",
    },
    img_dis: {
      type: String,
      default: "img/bh_red_nor.png",
    },
    active: {
      type: Number,
      default: 0,
    },
    playerNameList: {
      type: Array,
      default: () => ["a1", "a1", "a1", "a1"],
    },
  },
  methods: {
    clickBtn() {
      this.$emit("onThrowClick");
    },
  },
  mounted(){
    console.log("===")+this.isMatcher+this.isGroup;
  }
};
</script>

<style lang="scss" scoped>
.throw-area {
  width: 280px;
  height: 100%;
  border-radius: 16px;
  background-color: #292a2f;
  overflow: hidden;
  padding-bottom: 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 30px 20px;
  align-items: center;
  box-sizing: border-box;
  .player-list-wrap {
    width: 160px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .item {
      width: 80px;
      height: 90px;
      font-size: 12px;
      color: #ccc;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 30px;
        height: 30px;
        padding: 12px 0;
      }
    }
  }
  .send_btn {
    width: 60px;
    background: #38393b;
    //background:#ffffff;
    border-radius: 16px;
    padding: 20px 10px;
    cursor: pointer;
    color: #ccc;
    box-sizing: border-box;
  }

  .send_btn:focus {
    outline: 0;
  }

  .send_btn:hover {
    color: #999;
  }
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
.maoboli {
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(3px);
}
</style>
