<template>
  <div class="person_header_box">
    <div class="person_header_box_con">
      <div class="tai_ico" @click="to_big_match"></div>
      <div class="header_word" @click="goHome">返回首页</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.push({
        path: "/",
      });
      this.routerRefresh();
    },
    to_big_match() {
      location.href = "https://ai.secsa.cn";
    },
  },
};
</script>

<style scoped>
.person_header_box {
  width: 100%;
  height: 60px;
  background-image: url("/img/person_header_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.person_header_box_con {
  width: 900px;
  margin: 0 auto;
  /* padding-top: 18px; */
  height: 60px;
  overflow: hidden;
  position: relative;
}
.tai_ico {
  width: 122px;
  height: 42px;
  background-image: url("/img/tai_ico.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* float: left; */
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.header_word {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>
