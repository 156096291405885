<template>
  <div
    class="code-area"
    :class="[!isMatcher ? 'maoboli' : !isGroup ? 'maoboli' : '']"
  >
    <div class="code-area-header">“{{ groupName }}”编码区域</div>
    <st-codeediter ref="codeMirror1" v-on="$listeners"></st-codeediter>
    <div class="mask" v-show="!isMatcher ? true : !isGroup ? true : ''"></div>
  </div>
</template>

<script>
import CodeEditer from "./pythoncoder.vue";

export default {
  components: {
    "st-codeediter": CodeEditer,
  },
  props: {
    groupName: {
      type: String,
    },
    isMatcher: {
      type: Boolean,
      default: false,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.code-area {
  width: 280px;
  height: 100%;
  border-radius: 16px;
  background-color: #292a2f;
  overflow: hidden;
  padding-bottom: 16px;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  .code-area-header {
    padding: 10px;
    box-sizing: border-box;
    color: #fff;
    border-bottom: 1px solid #fff;
  }
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
.maoboli {
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  -o-filter: blur(2px);
  filter: blur(3px);
}
</style>
