<template>
  <div class="gameover">
    <div class="gameover_header">
      <span class="gameover_header_fir">上海市虚拟冰壶竞技编程</span>
      <span class="gameover_header_sec">@冬季奥林匹克运动会</span>
    </div>
    <div class="gameover_coutent">
      <div class="gameover_coutent_top">
        <div class="gameover_trophy"></div>
      </div>
      <div class="gameover_coutent_msg">
        <div class="gameover_coutent_msg_word">恭喜 xxxx 队获得本场胜利</div>
        <div class="go_to_home" @click="goBackHome">返回首页</div>
      </div>
    </div>
    <div class="gameover_footer">
      Copyright@ 2021 www.taimaker.com All Rights Reserved.
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goBackHome() {
      // 返回首页
      this.$router.push({
        path: "/",
      });
      this.routerRefresh();
    },
  },
};
</script>

<style scoped>
.gameover_header {
  min-width: 1024px;
  height: 60px;
  background-image: url("/img/person_header_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  line-height: 60px;
  text-align: left;
}
.gameover_header_fir {
  font-size: 24px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 600;
  color: #ffffff;
  margin-left: 24px;
}
.gameover_header_sec {
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 400;
  color: #ffffff;
}
.gameover_footer {
  min-width: 1024px;
  height: 143px;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  line-height: 143px;
  background-image: url("/img/home_bottom_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.gameover_coutent {
  min-width: 1024px;
}
.gameover_coutent_top {
  background-image: url("/img/gameover_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 220px;
  position: relative;
}
.gameover_trophy {
  background-image: url("/img/trophy.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 180px;
  height: 180px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -80px;
}
.gameover_coutent_msg {
  padding-top: 110px;
}
.gameover_coutent_msg_word {
  font-size: 24px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 500;
  color: #e19449;
  margin-bottom: 100px;
}
.go_to_home {
  width: 140px;
  height: 40px;
  background: linear-gradient(180deg, #4786ff 0%, #4869ff 100%);
  box-shadow: 0px 4px 8px 0px rgba(15, 68, 184, 0.4);
  border-radius: 2px;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, PingFang SC, "Hiragino Sans GB",
    "Microsoft YaHei", Arial, sans-serif;
  font-weight: 500;
  color: #ffffff;
  line-height: 40px;
  margin: 0 auto 70px;
  cursor: pointer;
}
</style>
